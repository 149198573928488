import api from '../../general/axiosInstance.js'

export default {
    getRespuestas() {
        return api.get('/getRespuestas')
    },

    getCategorias() {
        return api.get('/getCategoriasChequeos')
    },

    getChequeos() {
        return api.get('/getChequeos')
    },

    insertBitacoraInspec(data) {
        return api.post('/insertBitInspeccion', data)
    },

    insertBitacoraCheq(data) {
        return api.post('/insertBitChequeo', data)
    },

    getColores() {
        return api.get('/getColores')
    },

    getCarroceria(data) {
        return api.post('/getCarroceria', {categoria_veh: data})
    },

    getMarcas(data) {
        return api.post('/getMarcas', {categoria_veh: data})
    },

    insertMarca(data) {
        return api.post('/insertMarca', data)
    },

    insertCarroceria(data) {
        return api.post('/insertCarroceria', data)
    },

    insertColor(data) {
        return api.post('/insertColor', {color: data})
    },

    getServicios() {
        return api.get('/getServicios')
    },

    getTerminos() {
        return api.get('/getTerminos');
    },

    getPrivacidad() {
        return api.get('/getPrivacidad');
    },

    getChequeosCarro() {
        return api.get('/getChequeosCarro');
    },

    getChequeosMoto() {
        return api.get('/getChequeosMoto');
    },

    getEmpresas() {
        return api.get('/getEmpresas');
    },

    insertEmpresa(data) {
        return api.post('/insertEmpresa', {empresa: data})
    }
}