<template>
  <div>
    <!-- Contenido de la página que se revelará cuando todas las peticiones se hagan correctamente -->
    <div v-if="!page_loader">
      <v-card class="grey darken-3 justify-center text-center white--text">
        <v-card-title
          class="justify-center"
          style="font-size: 0.95rem"
          id="titulo-formulario"
        >
          Recepción del vehículo y orden de inspección</v-card-title
        >
      </v-card>

      <!-- Stepper que permitirá llenar de manera secuencial el formulario. Este mismo varía según cuántas
      categorías se tengan en bd -->
      <v-stepper v-model="index_stepper">
        <v-stepper-header>
          <v-stepper-step
            v-for="(categoria, index_header) in categorias_modificadas"
            :step="index_header + 1"
            :key="index_header"
            :complete="index_stepper > index_header"
          ></v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-for="(categoria, index_cat) in categorias_modificadas"
            :key="index_cat"
            :step="index_cat"
          >
            <v-card
              class="mx-auto justify-center text-center py-4"
              id="card-chequeo"
              elevation="0"
            >
              <div class="font-weight-bold text-center mb-4 mt-2 ml-2 mr-2">
                {{ categoria.categoria }}
              </div>

              <!-- 
              Formulario en el cual se van a llenar los datos del propietario.
              TODO: El funcionario encargado de la inspección es el mismo usuario que va a llenar este
              formulario.
              -->
              <v-form :ref="'form_' + index_cat" v-if="index_cat == 0">
                <div class="justify-center mt-2 mb-2 text-center px-4">
                  TIPO DE INSPECCIÓN
                  <v-radio-group
                    row
                    v-model="formulario_bitacora_inspec.tipo_inspeccion"
                    :rules="[rules.required]"
                  >
                    <v-radio
                      label="Inspección"
                      value="1"
                      class="justify-center d-flex"
                    ></v-radio>
                    <v-radio
                      label="Re-inspección"
                      value="2"
                      class="justify-center d-flex"
                    ></v-radio>
                  </v-radio-group>
                  NOMBRE DEL PROPIETARIO
                  <v-text-field
                    outlined
                    type="text"
                    :rules="[rules.required]"
                    v-model="formulario_bitacora_inspec.nombre_propietario"
                  ></v-text-field>

                  CÉDULA DE CIUDADANÍA
                  <v-text-field
                    outlined
                    type="number"
                    :rules="[rules.required, rules.numbers]"
                    v-model="formulario_bitacora_inspec.cedula"
                  ></v-text-field>

                  DIRECCIÓN Y CIUDAD
                  <v-text-field
                    outlined
                    type="text"
                    :rules="[rules.required]"
                    v-model="formulario_bitacora_inspec.direccion"
                  ></v-text-field>

                  TELÉFONO (CELULAR Y/O FIJO)
                  <v-text-field
                    outlined
                    type="number"
                    :rules="[rules.required, rules.numbers]"
                    v-model="formulario_bitacora_inspec.telefono"
                  ></v-text-field>

                  CORREO ELECTRÓNICO
                  <v-text-field
                    outlined
                    type="email"
                    :rules="[rules.required, rules.email]"
                    v-model="formulario_bitacora_inspec.email"
                  ></v-text-field>

                  HORA DE SALIDA
                  <v-menu
                    ref="hora_salida"
                    v-model="menu_hora_salida"
                    :close-on-content-click="false"
                    :return-value.sync="formulario_bitacora_inspec.hora_salida"
                    transition="scroll-y-transition"
                    offset-y
                    max-width="285px"
                    min-width="285px"
                    height="355px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formulario_bitacora_inspec.hora_salida"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>

                    <v-time-picker
                      v-if="menu_hora_salida"
                      v-model="formulario_bitacora_inspec.hora_salida"
                      full-width
                      ampm-in-title
                      @click:minute="
                        $refs.hora_salida[0].save(
                          formulario_bitacora_inspec.hora_salida
                        )
                      "
                    ></v-time-picker>
                  </v-menu>

                  OT INICIAL
                  <v-text-field
                    outlined
                    type="text"
                    :rules="[rules.required]"
                    v-model="formulario_bitacora_inspec.ot_inicial"
                  ></v-text-field>

                  OT DE REINSPECCIÓN
                  <v-text-field
                    outlined
                    type="text"
                    :rules="[rules.required]"
                    v-model="formulario_bitacora_inspec.ot_reinspec"
                  ></v-text-field>

                  <div>
                    <span class="ml-8">FIRMA</span>
                    <v-btn
                      class="ml-16"
                      icon
                      color="error"
                      @click="limpiarFirmaPropietario()"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                  <VueSignaturePad
                    width="276px"
                    height="246px"
                    ref="signaturePadFirma"
                    id="canvas"
                    class="mb-2"
                    :options="{
                      onBegin: () => {
                        $refs.signaturePadFirma[0].resizeCanvas();
                      },
                    }"
                    :scaleToDevicePixelRatio="false"
                  >
                  </VueSignaturePad>

                  <div class="caption">
                    * FIRMA COMO CONSTANCIA DE LA ENTREGA A CONFORMIDAD DEL
                    VEHÍCULO, DE LOS DOCUMENTOS DE ESTE, DEL CERTIFICADO Y DEL
                    FORMATO UNIFORME DE RESULTADOS (FUR) DEBIDAMENTE FIRMADO POR
                    EL DIRECTOR TÉCNICO AUTORIZADO POR EL CDA.
                  </div>
                </div>
              </v-form>

              <!-- Paneles de expansión los cuales van a retener información sobre los términos, condiciones,
                y derechos de privacidad los cuales se pueden mostrar o no mediante config. Estos paneles
                se van a mostrar al final del formulario. -->
              <div
                class="justify-center mt-2 mb-2 text-justify px-1"
                v-if="index_cat == categorias.length - 1"
              >
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Notificación referente a la inspección del vehículo y
                      condiciones contractuales
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="caption">
                      <div class="mb-2">
                        Por medio del presente documento solicito al Centro de
                        Diagnóstico Automotor CDA CERTIEXPRESS CARTAGO S.A.S. se
                        le practique al vehículo del cual soy propietario,
                        poseedor o tenedor la Revisión Técnico Mecánica y de
                        Emisiones Contaminantes Emitidas por Fuentes Móviles
                        Vehiculares; según lo establece la ley 769 de 2002 en su
                        Artículo 28, la resolución 3768 de 2013 y las demás que
                        la modifiquen o sustituyan y acepto las siguientes
                        condiciones:
                      </div>

                      <ul>
                        <li
                          class="mb-2"
                          v-for="(item, index) in condiciones_contractuales"
                          :key="index"
                        >
                          {{ item.config }}
                        </li>
                      </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Aviso de Privacidad, confidencialidad y protección de
                      datos
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="caption">
                      <div class="mb-2">
                        El presente documento constituye el Aviso de privacidad,
                        confidencialidad, protección de datos y mecanismos
                        alternos para solicitar la autorización de los titulares
                        de los datos personales administrados por el CDA
                        CERTIEXPRESS CARTAGO S.A.S. para poner a su disposición
                        las políticas y para continuar con el tratamiento de la
                        información. CERTIEXPRESS CARTAGO S.A.S., Nit
                        900406671-0, en adelante El CDA, con domicilio en la Cr
                        4 No 19-63 del Municipio de Cartago en su condición de
                        responsable de tratamiento de datos personales y dando
                        cumplimiento a lo dispuesto en la Ley estatutaria 1581
                        de 2012, el Decreto 1377 de 2013 y demás normas que lo
                        adicionen o modifiquen relativas a la protección de
                        datos personales expedidas en desarrollo del derecho
                        constitucional de todas las personas, a conocer,
                        actualizar y rectificar de forma gratuita la información
                        que se recaude sobre ellas en las bases de datos o
                        archivos y los derechos libertades y garantías a los que
                        se refiere el Artículo 156 y 20 de la Constitución
                        Política de Colombia, se permite informar lo siguiente:
                      </div>

                      <ul>
                        <li
                          class="mb-2"
                          v-for="(item, index) in privacidad"
                          :key="index"
                        >
                          {{ item.config }}
                        </li>
                      </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- Checkbox que confirma que el propietario acepta los términos y condiciones y el cual
                habilita el poder guardar el contenido del formulario -->
                <v-checkbox
                  label="Acepto los lineamientos y condiciones contractuales relacionados en el presente 
                documento y Manifiesto que la presente autorización me fue solicitada antes de entregar 
                mis datos y la suscribo de forma libre y voluntaria una vez leída en su totalidad al 
                igual que la Política de Tratamiento de Datos Personales."
                  id="checkbox-politica"
                  v-model="acepta_privacidad"
                  :disabled="loader_insert_formulario"
                >
                </v-checkbox>
              </div>

              <!-- Formulario en el cual su contenido depende de la cantidad de chequeos que hayan en bd -->
              <v-form :ref="'form_' + index_cat">
                <div
                  v-for="(chequeo, index_cheq) in categoria.chequeos"
                  :key="index_cheq"
                  class="justify-center mt-2 mb-2 text-center px-4"
                >
                  <v-row class="justify-center d-flex">
                    <v-col cols="12">
                      <!-- Nombre del chequeo -->
                      <label>
                        <v-row v-if="chequeo.id_tipo_respuesta == 1">
                          <v-col cols="9">
                            {{ chequeo.chequeo }}
                          </v-col>

                          <v-col cols="3" class="align-center">
                            <!-- Checkbox que solo se renderiza si el chequeo requiere de una sola respuesta, que 
                        es sí. -->
                            <v-checkbox
                              v-if="chequeo.id_tipo_respuesta == 1"
                              label="Sí"
                              true-value="1"
                              v-model="chequeo.respuesta"
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>

                        <span v-else>{{ chequeo.chequeo }}</span>

                        <!-- Botones que permiten abrir o cerrar un formulario que permite 
                        añadir nuevos elementos, como marcas, tipos de carrocería y colores. -->
                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="primary"
                          v-show="
                            chequeo.chequeo == 'MARCA' && !activarCrearMarca
                          "
                          @click="activarCrearMarca = true"
                          :disabled="disable_nav_buttons"
                        >
                          <v-icon x-small>mdi-plus</v-icon>
                          Crear
                        </v-btn>

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="error"
                          v-show="
                            chequeo.chequeo == 'MARCA' && activarCrearMarca
                          "
                          @click="activarCrearMarca = false"
                          :disabled="loader_insert_marca"
                        >
                          <v-icon x-small>mdi-close</v-icon>
                          Cancelar
                        </v-btn>

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="success"
                          v-show="
                            chequeo.chequeo == 'MARCA' && activarCrearMarca
                          "
                          @click="insertMarca()"
                          :loading="loader_insert_marca"
                        >
                          <v-icon x-small>mdi-check</v-icon>
                          Guardar
                        </v-btn>

                        <!-- ---------------------------------------------------- -->

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="primary"
                          v-show="
                            chequeo.chequeo == 'CARROCERIA' &&
                            !activarCrearCarroceria
                          "
                          @click="activarCrearCarroceria = true"
                          :disabled="disable_nav_buttons"
                        >
                          <v-icon x-small>mdi-plus</v-icon>
                          Crear
                        </v-btn>

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="error"
                          v-show="
                            chequeo.chequeo == 'CARROCERIA' &&
                            activarCrearCarroceria
                          "
                          @click="activarCrearCarroceria = false"
                          :disabled="loader_insert_carroceria"
                        >
                          <v-icon x-small>mdi-close</v-icon>
                          Cancelar
                        </v-btn>

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="success"
                          v-show="
                            chequeo.chequeo == 'CARROCERIA' &&
                            activarCrearCarroceria
                          "
                          @click="insertCarroceria()"
                          :loading="loader_insert_carroceria"
                        >
                          <v-icon x-small>mdi-check</v-icon>
                          Guardar
                        </v-btn>

                        <!-- ---------------------------------------------------- -->

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="primary"
                          v-show="
                            chequeo.chequeo == 'COLOR' && !activarCrearColor
                          "
                          @click="activarCrearColor = true"
                        >
                          <v-icon x-small>mdi-plus</v-icon>
                          Crear
                        </v-btn>

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="error"
                          v-show="
                            chequeo.chequeo == 'COLOR' && activarCrearColor
                          "
                          @click="activarCrearColor = false"
                          :disabled="loader_insert_color"
                        >
                          <v-icon x-small>mdi-close</v-icon>
                          Cancelar
                        </v-btn>

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="success"
                          v-show="
                            chequeo.chequeo == 'COLOR' && activarCrearColor
                          "
                          @click="insertColor()"
                          :loading="loader_insert_color"
                        >
                          <v-icon x-small>mdi-check</v-icon>
                          Guardar
                        </v-btn>

                        <!-- --------------------------------------------------- -->

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="primary"
                          v-show="
                            chequeo.chequeo == 'EMPRESA' && !activarCrearEmpresa
                          "
                          @click="activarCrearEmpresa = true"
                        >
                          <v-icon x-small>mdi-plus</v-icon>
                          Crear
                        </v-btn>

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="error"
                          v-show="
                            chequeo.chequeo == 'EMPRESA' && activarCrearEmpresa
                          "
                          @click="activarCrearEmpresa = false"
                          :disabled="loader_insert_empresa"
                        >
                          <v-icon x-small>mdi-close</v-icon>
                          Cancelar
                        </v-btn>

                        <v-btn
                          class="ml-1"
                          x-small
                          text
                          color="success"
                          v-show="
                            chequeo.chequeo == 'EMPRESA' && activarCrearEmpresa
                          "
                          :loading="loader_insert_empresa"
                          @click="insertEmpresa()"
                        >
                          <v-icon x-small>mdi-check</v-icon>
                          Guardar
                        </v-btn>
                      </label>

                      <div class="text-center d-flex justify-center">

                        <!--Canvas en el cual se permite dibujar sobre una imagen, y se usa principalmente
                        para diagnosticar daños en el vehículo subrayando mediante un círculo. El canvas 
                        que se muestra también depende del tipo del vehículo. -->
                        <div v-if="!cambiar_canvas_moto">
                          <VueSignaturePad
                            v-if="chequeo.chequeo.includes('DAÑOS')"
                            width="277px"
                            height="219px"
                            ref="signaturePadDanos"
                            :options="{
                              onBegin: () => {
                                $refs.signaturePadDanos[0].resizeCanvas();
                              },
                            }"
                            id="canvas-danos"
                            class="mb-2"
                            :scaleToDevicePixelRatio="false"
                          ></VueSignaturePad>

                          <v-btn
                            color="success"
                            text
                            v-if="chequeo.chequeo.includes('DAÑOS')"
                            @click="guardarImagenDanos()"
                            :loading="loader_guardar_danos"
                          >
                            Guardar
                          </v-btn>

                          <v-btn
                            color="error"
                            text
                            v-if="chequeo.chequeo.includes('DAÑOS')"
                            @click="limpiarImagenDanos()"
                            :disabled="loader_guardar_danos"
                          >
                            Limpiar
                          </v-btn>
                        </div>

                        <div v-else>
                          <VueSignaturePad
                            v-if="chequeo.chequeo.includes('DAÑOS')"
                            width="277px"
                            height="219px"
                            ref="signaturePadDanosMotos"
                            :options="{
                              onBegin: () => {
                                $refs.signaturePadDanosMotos[0].resizeCanvas();
                              },
                            }"
                            id="canvas-danos-motos"
                            class="mb-2"
                            :scaleToDevicePixelRatio="false"
                          ></VueSignaturePad>

                          <v-btn
                            color="success"
                            text
                            v-if="chequeo.chequeo.includes('DAÑOS')"
                            @click="guardarImagenDanosMotos()"
                            :loading="loader_guardar_danos_motos"
                          >
                            Guardar
                          </v-btn>

                          <v-btn
                            color="error"
                            text
                            v-if="chequeo.chequeo.includes('DAÑOS')"
                            @click="limpiarImagenDanosMotos()"
                            :disabled="loader_guardar_danos_motos"
                          >
                            Limpiar
                          </v-btn>
                        </div>

                        <!-- Autocompletados que se utilizan para seleccionar o buscar una marca, tipo de
                        carrocería, color, servicio o empresa en específico. -->
                        <v-autocomplete
                          v-if="
                            chequeo.chequeo == 'MARCA' && !activarCrearMarca
                          "
                          :items="marcas"
                          item-text="marca"
                          item-value="marca"
                          outlined
                          hide-no-data
                          placeholder="Seleccione una marca"
                          v-model="chequeo.texto"
                          :disabled="disable_nav_buttons"
                          :rules="[rules.required]"
                        ></v-autocomplete>

                        <v-autocomplete
                          v-if="
                            chequeo.chequeo == 'CARROCERIA' &&
                            !activarCrearCarroceria
                          "
                          :items="carroceria"
                          item-text="carroceria"
                          item-value="carroceria"
                          outlined
                          hide-no-data
                          placeholder="Seleccione un tipo de carrocería"
                          v-model="chequeo.texto"
                          :disabled="disable_nav_buttons"
                          :rules="[rules.required]"
                        ></v-autocomplete>

                        <v-autocomplete
                          v-if="
                            chequeo.chequeo == 'COLOR' && !activarCrearColor
                          "
                          :items="colores"
                          item-text="color"
                          item-value="color"
                          outlined
                          hide-no-data
                          placeholder="Seleccione un color"
                          v-model="chequeo.texto"
                          :rules="[rules.required]"
                        ></v-autocomplete>

                        <v-autocomplete
                          v-if="chequeo.chequeo == 'SERVICIO'"
                          :items="servicios"
                          item-text="servicio"
                          item-value="servicio"
                          outlined
                          hide-no-data
                          placeholder="Seleccione un servicio"
                          v-model="chequeo.texto"
                          :rules="[rules.required]"
                        ></v-autocomplete>

                        <v-autocomplete
                          v-if="(chequeo.chequeo == 'EMPRESA' && !activarCrearEmpresa)"
                          :items="empresas"
                          item-text="empresa"
                          item-value="empresa"
                          outlined
                          hide-no-data
                          placeholder="Seleccione una empresa"
                          v-model="chequeo.texto"
                          :rules="[rules.required]"
                        ></v-autocomplete>

                        <!-- Formulario que permite añadir una empresa a la lista -->
                        <v-form ref="form_empresa">
                          <v-text-field
                            v-if="chequeo.chequeo == 'EMPRESA' && activarCrearEmpresa"
                            outlined
                            placeholder="Empresa"
                            v-model="formulario_empresa"
                          >
                          </v-text-field>
                        </v-form>

                        <!-- Formulario que permite añadir una marca a la lista, en conjunto con su 
                        tipo de vehículo -->
                        <v-form ref="form_marca">
                          <v-text-field
                            v-if="
                              chequeo.chequeo == 'MARCA' && activarCrearMarca
                            "
                            outlined
                            placeholder="Marca"
                            v-model="formulario_marca.marca"
                          ></v-text-field>

                          <v-select
                            v-if="
                              chequeo.chequeo == 'MARCA' && activarCrearMarca
                            "
                            outlined
                            placeholder="Tipo vehículo"
                            :items="tipos_vehiculo"
                            item-text="tipo"
                            item-value="id_tipo"
                            v-model="formulario_marca.categoria_veh"
                          ></v-select>
                        </v-form>

                        <!-- Formulario que permite añadir un tipo de carrocería a la lista, en conjunto con
                        el tipo de vehículo al que pertenece. -->
                        <v-form ref="form_carroceria">
                          <v-text-field
                            v-if="
                              chequeo.chequeo == 'CARROCERIA' &&
                              activarCrearCarroceria
                            "
                            outlined
                            placeholder="Carrocería"
                            v-model="formulario_carroceria.carroceria"
                          ></v-text-field>

                          <v-select
                            v-if="
                              chequeo.chequeo == 'CARROCERIA' &&
                              activarCrearCarroceria
                            "
                            outlined
                            placeholder="Tipo vehículo"
                            :items="tipos_vehiculo"
                            item-text="tipo"
                            item-value="id_tipo"
                            v-model="formulario_carroceria.tipo_vehiculo"
                          ></v-select>
                        </v-form>

                        <!-- Formulario que permite añadir un color a la lista -->
                        <v-form ref="form_color">
                          <v-text-field
                            v-if="
                              chequeo.chequeo == 'COLOR' && activarCrearColor
                            "
                            outlined
                            placeholder="Escriba el nombre del color"
                            v-model="formulario_color"
                          ></v-text-field>
                        </v-form>

                        <!-- Grupo de botones tipo radio que determinan si la respuesta es sí, 
                        no o no aplica. -->
                        <v-radio-group
                          row
                          v-if="chequeo.id_tipo_respuesta == 2"
                          class="justify-center mx-auto"
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                        >
                          <v-radio
                            v-for="(respuesta, index_resp) in respuestas[1]"
                            :key="index_resp"
                            :label="respuesta.respuesta"
                            :value="respuesta.id_respuesta"
                          >
                          </v-radio>
                        </v-radio-group>

                        <!-- Select en el cual se mostrarán los tipos de motor existentes. -->
                        <v-select
                          v-if="chequeo.id_tipo_respuesta == 3"
                          :items="respuestas[2]"
                          item-text="respuesta"
                          item-value="id_respuesta"
                          outlined
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                        >
                        </v-select>

                        <!-- Select que permite determinar el tipo de vehículo, si es un liviano o
                        una motocicleta. -->
                        <v-select
                          v-if="chequeo.id_tipo_respuesta == 4"
                          :items="respuestas[3]"
                          item-text="respuesta"
                          item-value="id_respuesta"
                          outlined
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                          @change="validarTipoVehiculo(chequeo.respuesta)"
                          placeholder="Liviano"
                        >
                        </v-select>

                        <!-- Select que permite determinar la tipología del vehículo, siendo estas
                        opciones preventiva y enseñanza. -->
                        <v-select
                          v-if="chequeo.id_tipo_respuesta == 5"
                          :items="respuestas[4]"
                          item-text="respuesta"
                          item-value="id_respuesta"
                          outlined
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                        >
                        </v-select>

                        <!-- Grupo de botones radio que determinan si el chequeo cumple o no aplica. -->
                        <v-radio-group
                          row
                          v-if="chequeo.id_tipo_respuesta == 6"
                          class="justify-center mx-auto"
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                        >
                          <v-radio
                            v-for="(respuesta, index_resp) in respuestas[5]"
                            :key="index_resp"
                            :label="respuesta.respuesta"
                            :value="respuesta.id_respuesta"
                          >
                          </v-radio>
                        </v-radio-group>

                        <!-- Grupo de botones radio que determinan si el elemento no aplica o en su defecto
                        no lo tiene -->
                        <v-radio-group
                          row
                          v-if="chequeo.id_tipo_respuesta == 7"
                          class="justify-center mx-auto"
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                        >
                          <v-radio
                            v-for="(respuesta, index_resp) in respuestas[6]"
                            :key="index_resp"
                            :label="respuesta.respuesta"
                            :value="respuesta.id_respuesta"
                          >
                          </v-radio>
                        </v-radio-group>

                        <!-- Select que permite determinar el tipo de combustible del vehículo -->
                        <v-select
                          v-if="chequeo.id_tipo_respuesta == 8"
                          :items="respuestas[7]"
                          item-text="respuesta"
                          item-value="id_respuesta"
                          outlined
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                        >
                        </v-select>

                        <!-- Grupo de botones radio que permite determinar si el elemento cumple o no con
                        las condiciones mediante un sí/no. -->
                        <v-radio-group
                          row
                          v-if="chequeo.id_tipo_respuesta == 9"
                          class="justify-center mx-auto"
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                        >
                          <v-radio
                            v-for="(respuesta, index_resp) in respuestas[8]"
                            :key="index_resp"
                            :label="respuesta.respuesta"
                            :value="respuesta.id_respuesta"
                          >
                          </v-radio>
                        </v-radio-group>

                        <!-- Select que permite determinar el peso bruto vehicular. -->
                        <v-select
                          v-if="chequeo.id_tipo_respuesta == 10"
                          :items="respuestas[9]"
                          item-text="respuesta"
                          item-value="id_respuesta"
                          outlined
                          v-model="chequeo.respuesta"
                          :rules="[rules.required]"
                        >
                        </v-select>

                        <!-- Campo de texto en el cual se permite cualquier tipo de respuesta abierta, si
                        el chequeo lo requiere. Este campo puede alternar entre el tipo number y text, al
                        igual que su sufijo y sus reglas, mediante una serie de banderas. -->
                        <v-text-field
                          v-if="chequeo.id_tipo_respuesta == 11"
                          outlined
                          :suffix="
                            chequeo.chequeo.includes('(PSI)')
                              ? 'psi'
                              : chequeo.chequeo == 'KILOMETRAJE'
                              ? 'km'
                              : ''
                          "
                          v-model="chequeo.texto"
                          :rules="
                            chequeo.tiene_valor_numerico == 1
                              ? [rules.required, rules.numbers]
                              : [rules.required]
                          "
                          :type="
                            chequeo.tiene_valor_numerico == 1
                              ? 'number'
                              : 'text'
                          "
                          :min="chequeo.tiene_valor_numerico == 1 ? '0' : ''"
                        ></v-text-field>

                        <!-- Calendario que permite seleccionar la fecha de expedición del certificado. 
                        Se activa mediante un slot de activación. -->
                        <v-menu
                          ref="calendarioCertificado"
                          v-model="activar_calendario_certificado"
                          :close-on-content-click="false"
                          :return-value.sync="chequeo.texto"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          v-if="chequeo.chequeo == 'CERTIFICADO DE REVISIÓN DE LA INSTALACIÓN GNV'"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="chequeo.texto"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              :rules="[rules.required]"
                            >
                            </v-text-field>
                          </template>

                          <v-date-picker
                            v-model="chequeo.texto"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            
                            <v-btn
                              text
                              color="primary"
                              @click="(activar_calendario_certificado == false)"
                            >
                              Cancelar
                            </v-btn>

                            <v-btn
                              text
                              color="primary"
                              @click="$refs.calendarioCertificado[0].save(chequeo.texto)"
                            >
                              Confirmar
                            </v-btn>
                          </v-date-picker>
                        </v-menu>

                        <!-- Calendario que permite seleccionar la fecha de vigencia del certificado.
                        Se activa mediante un slot de activación puesto en un campo de texto. -->
                        <v-menu
                          ref="calendarioVigencia"
                          v-model="activar_calendario"
                          :close-on-content-click="false"
                          :return-value.sync="chequeo.texto"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          v-if="chequeo.chequeo == 'VIGENCIA'"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="chequeo.texto"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              :rules="[rules.required]"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="chequeo.texto"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="activar_calendario = false"
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.calendarioVigencia[0].save(chequeo.texto)
                              "
                            >
                              Confirmar
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-form>

              <!-- Botones que permiten avanzar, retroceder y guardar el formulario. Si se desea avanzar
              o guardar, primero se valida esa parte del formulario -->

              <div class="mt-3 mb-1">
                <v-btn
                  color="primary"
                  v-if="index_cat == 0"
                  @click="siguiente('form_' + index_cat)"
                  :disabled="disable_nav_buttons"
                >
                  <v-icon class="mr-1">mdi-arrow-right-bold-circle</v-icon>
                  Siguiente
                </v-btn>

                <div class="mt-3 mb-2">
                  <v-btn
                    color="primary"
                    v-if="
                      index_cat !== 0 && index_cat !== categorias.length - 1
                    "
                    @click="siguiente('form_' + index_cat)"
                    :disabled="disable_nav_buttons"
                  >
                    <v-icon class="mr-1">mdi-arrow-right-bold-circle</v-icon>
                    Siguiente
                  </v-btn>
                </div>
                <div class="mt-3 mb-1">
                  <v-btn
                    color="primary"
                    v-if="
                      index_cat !== 0 && index_cat !== categorias.length - 1
                    "
                    @click="anterior()"
                    :disabled="disable_nav_buttons"
                  >
                    <v-icon class="mr-1">mdi-arrow-left-bold-circle</v-icon>
                    Atrás
                  </v-btn>
                </div>

                <div class="mt-3 mb-2">
                  <v-btn
                    color="success"
                    v-if="index_cat == categorias.length - 1"
                    :disabled="acepta_privacidad == false ? true : false"
                    @click="insertBitacoraCheq('form_' + index_cat)"
                    :loading="loader_insert_formulario"
                  >
                    <v-icon class="mr-1">mdi-check</v-icon>
                    Guardar
                  </v-btn>
                </div>

                <div class="mt-3 mb-1">
                  <v-btn
                    color="primary"
                    v-if="index_cat == categorias.length - 1"
                    @click="anterior()"
                    :disabled="disable_nav_buttons"
                  >
                    <v-icon class="mr-1">mdi-arrow-left-bold-circle</v-icon>
                    Atrás
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>

        <!-- Alertas que se lanzan en caso de que se guarden los datos bien sea del propietario o del
        formulario en sí, o cuando falta algún chequeo que no se haya llenado. -->
        <v-row class="justify-center d-flex text-center align-center">
          <v-col cols="12">
            <v-alert
              dense
              type="success"
              width="300px"
              style="
                position: fixed;
                left: 50%;
                bottom: 5%;
                transform: translate(-50%, 50%);
              "
              transition="scroll-y-reverse-transition"
              v-if="notif_datos_guardados"
            >
              <span class="font-weight-bold">¡Datos guardados!</span>
            </v-alert>
          </v-col>
        </v-row>

        <v-row class="justify-center d-flex text-center align-center">
          <v-col cols="12">
            <v-alert
              dense
              type="error"
              width="300px"
              style="
                position: fixed;
                left: 50%;
                bottom: 5%;
                transform: translate(-50%, 50%);
              "
              transition="scroll-y-reverse-transition"
              v-if="notif_validacion"
            >
              <span class="font-weight-bold"
                >Faltan campos por llenar. Por favor intenta de nuevo.</span
              >
            </v-alert>
          </v-col>
        </v-row>
      </v-stepper>
    </div>

    <!-- Spinner el cual se muestra cuando los requests que se hacen al cargar la página no están 
    terminados.-->
    <div
      v-else
      class="d-flex justify-center align-center"
      style="height: 100vh"
    >
      <v-progress-circular
        color="primary"
        :size="90"
        :width="9"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import apiRecepcion from "./services/RecepcionDataService.js";

import Vue from "vue";
import VueSignaturePad from "vue-signature-pad";
import mergeImages from "merge-images";
import imagen_carro from "../../assets/revision_carro_resize.png";
import imagen_moto from "../../assets/revision_moto_resize.png";
import fondo_blanco from "../../assets/fondo_blanco_resize.png";

Vue.use(VueSignaturePad);

export default {
  data() {
    return {
      respuestas: [], // Lista de respuestas a los chequeos
      categorias: [], // Lista de categorías que catalogan a los chequeos
      chequeos: [], // Lista de chequeos del formulario
      categorias_modificadas: [], // Lista de categorías que han sido modificadas
      colores: [], // Lista de colores disponibles
      carroceria: [], // Lista de carrocería disponible
      marcas: [], // Lista de marcas disponibles
      servicios: [], // Lista de servicios disponibles
      index_stepper: 1, // Número de step actual
      cambiar_canvas_moto: false, //Cambia la imagen del canvas al de motocicleta

      // Formulario que se usa como data para enviar a la bd
      formulario_bitacora_inspec: {
        tipo_inspeccion: "",
        nombre_propietario: "",
        cedula: "",
        direccion: "",
        telefono: "",
        email: "",
        hora_salida: "",
        ot_inicial: "",
        ot_reinspec: "",
        firma: "",
      },

      //Array que contiene un ID y un nombre de tipo de vehículo, usable en un select en el cual se selecciona el tipo de vehiculo
      tipos_vehiculo: [
        {
          id_tipo: 1,
          tipo: "Liviano",
        },
        {
          id_tipo: 2,
          tipo: "Motocicleta",
        },
      ],

      menu_hora_salida: false, //Activa el time picker al seleccionar la hora de salida

      id_bitacora_inspeccion: null, //Recoge el ID de la inspección

      imagenes_revisiones: [], //Array que contiene una imagen bien sea de moto o carro, para el canvas.

      disable_nav_buttons: false, //Desactiva los botones de navegación temporalmente.

      fondo_blanco: "", //Guarda el base64 de un fondo blanco disponible en los assets.

      // Objeto que define las reglas de validación de un formulario. Si encuentra alguna coincidencia con
      // Alguna de estas expresiones regulares se lanza un mensaje de error
      rules: {
        required: (v) => !!v || "Campo requerido.",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Email inválido.";
        },
        numbers: (v) => {
          const pattern = /^[0-9\s]*$/;
          return pattern.test(v) || "Solo números están permitidos.";
        },
      },

      loader_formulario_inspec: false, //Loader que aparece cuando insertamos en bitácora de inspección

      formulario_bitacora_cheq: [], // Objeto que guardará todo el formulario de chequeo. Al guardar, se llena.

      notif_validacion: false, // Notificación que aparece cuando falta un campo por llenar.

      notif_datos_propietario: false, // Notificación que aparece cuando los datos del propietario han sido guardados.

      notif_datos_guardados: false, // Notificación que aparece cuando los datos hayan sido guardados.

      page_loader: true, // Spinner que aparece mientras carga la información necesaria para el formulario.

      /* Activadores que permiten alternar entre un input y otro cuando queremos crear una marca, carrocería,
      color o empresa de GNV nueva. */
      activarCrearMarca: false,

      activarCrearCarroceria: false,

      activarCrearColor: false,

      activarCrearEmpresa: false,

      //Objeto que almacenará información sobre la marca a insertar.
      formulario_marca: {
        marca: "",
        categoria_veh: "",
      },

      //Objeto que guardará información sobre el tipo de carrocería a insertar.
      formulario_carroceria: {
        carroceria: "",
        tipo_vehiculo: "",
      },

      //String que guardará el color que queramos insertar.
      formulario_color: "",

      //String que guardará la empresa certificadora que queramos insertar.
      formulario_empresa: "",

      //Respectivos spinners que se activan al crear una nueva marca, color, carrocería o empresa.
      loader_insert_marca: false,

      loader_insert_carroceria: false,

      loader_insert_color: false,

      loader_insert_empresa: false,

      //Array de condiciones contractuales, presente en la última parte del formulario.
      condiciones_contractuales: [],

      privacidad: [], // Array de política de privacidad, presente en la última parte del formulario.

      //Lista de chequeos exclusivos a cada tipo de vehículo.
      chequeos_especificos: [],

      //Booleano que activa el botón de enviar si el usuario acepta el acuerdo contractual y de privacidad.
      acepta_privacidad: false,

      //Spinner que aparece al enviar el formulario.
      loader_insert_formulario: false,

      //Activa un date picker.
      activar_calendario: false,

      //Lista de empresas disponibles.
      empresas: [], 

      //Activa un date picker el cual podemos elegir las fechas vigentes del certificado.
      activar_calendario_certificado: false,

      //Spinner que aparece cuando queremos guardar el canvas de daños tanto de carro como de moto.
      loader_guardar_danos: false,

      loader_guardar_danos_motos: false
    };
  },

  /* Antes de renderizar la vista como tal se hacen unas requests a la base de datos que permita traer
     las categorías, los chequeos y filtra iterando entre ambos */
  async created() {
    await apiRecepcion.getCategorias().then((response) => {
      this.categorias = response.data;
      apiRecepcion.getChequeos().then((response) => {
        this.chequeos = response.data;

        /* Itera sobre las categorías y chequeos, y si el id de categoría en el chequeo coincide con el id
           de categoría se le asigna una respuesta y un valor para respuestas abiertas */
        this.categorias.forEach((categoria) => {
          Object.assign(categoria, { chequeos: [] }); // Se asigna un array de chequeos
          this.chequeos.forEach((chequeo) => {
            if (chequeo.id_categoria == categoria.id_categoria) {
              Object.assign(chequeo, { respuesta: "" });
              Object.assign(chequeo, { texto: "" });
              categoria.chequeos.push(chequeo); // Hace push en el array asignado anteriormente
            }
          });
        });

        /* Las categorías que ya pasaron por este proceso se asignan a otro array */
        this.categorias_modificadas = this.categorias;
      });
    });

    // Consigue la hora actual en formato hh:ss
    this.getHoraActual();

    // Consigue todos los colores
    this.getColores();

    // Consigue los tipos de carrocería de liviano
    this.getCarroceria("1");

    // Consigue todas las marcas de liviano
    this.getMarcas("1");

    // Consigue los tipos de servicios existentes
    this.getServicios();

    // Consigue los términos contractuales que hay en configuración
    this.getTerminosContractuales();

    // Consigue todas las empresas certificadoras activas
    this.getEmpresas();

    /* Consigue todas las respuestas a estos chequeos */
    await this.getRespuestas();

    /* Después de que el DOM se actualice, el número del stepper cambia*/
    this.$nextTick(() => {
      this.index_stepper = 0;
    });

    /* Una vez se pase por todo el proceso, el spinner que cubre toda la página desaparece */
    this.page_loader = false;
  },

  mounted() {
    /* Consigue las URL o strings de base64 de las imagenes que se hayan importado en el script */
    this.getImageURL();
    this.getFondoBlanco();
  },

  /* Watchers que permiten detectar un valor nuevo de una variable determinada. Una vez esa variable detecte 
     un valor nuevo se pondrá un timeout de 2500ms y cuando se acabe ese tiempo la notificación desaparecerá */
  watch: {
    notif_validacion(val) {
      if (val) {
        setTimeout(() => {
          this.notif_validacion = false;
        }, 2500);
      }
    },

    notif_datos_propietario(val) {
      if (val) {
        setTimeout(() => {
          this.notif_datos_propietario = false;
        }, 2500);
      }
    },

    notif_datos_guardados(val) {
      if (val) {
        setTimeout(() => {
          this.notif_datos_guardados = false;
        }, 2500);
      }
    },
  },

  methods: {
    /* Función que consigue todas las respuestas */
    async getRespuestas() {
      await apiRecepcion.getRespuestas().then((response) => {
        this.respuestas = response.data;
      });
    },

    /* Permite avanzar en el formulario, dependiendo en qué step se encuentre. Valida esa parte del
       formulario, y si no hay algún error, se avanza y devuelve el scroller a su posición inicial. */
    siguiente(step) {
      if (this.$refs[step][0].validate()) {
        this.index_stepper++;
        window.scrollTo(0, 0);
        this.guardarFirmaPropietario();
      } else {
        this.notif_validacion = true;
      }
    },

    /* Permite retroceder en el formulario */
    anterior() {
      this.index_stepper--;
      window.scrollTo(0, 0);
    },

    /* Consigue la hora actual y la asigna a la hora de salida */
    getHoraActual() {
      var time = new Date();

      var formatted_time =
        ("0" + time.getHours()).slice(-2) +
        ":" +
        ("0" + time.getMinutes()).slice(-2);

      this.formulario_bitacora_inspec.hora_salida = formatted_time;
    },

    /* Inserta en la bitácora de inspección si el formulario no contiene errores al validar. */
    async insertBitacoraInspec() {
      this.loader_formulario_inspec = true;

      await apiRecepcion
        .insertBitacoraInspec(this.formulario_bitacora_inspec)
        .then((response) => {
          this.loader_formulario_inspec = false;
          this.id_bitacora_inspeccion = response.data; // Se guarda el ID del registro que fue insertado
        })
        .catch((error) => {
          this.loader_formulario_inspec = false;
          console.log(error); // Emite el error por consola
        });
    },

    /* Inserta en la bitácora de chequeos, si el formulario ha sido validado correctamente */
    async insertBitacoraCheq(formstep) {
      if (this.$refs[formstep][0].validate()) {
        this.loader_insert_formulario = true;

        await this.insertBitacoraInspec();
        /* Itera por cada categoría y a su vez por cada chequeo que haya dentro de la misma. A continuación,
           pushea en otro array aparte en donde se enviarán los datos de un modo mucho más organizado */
        this.categorias_modificadas.forEach((categoria) => {
          categoria.chequeos.forEach((chequeo) => {
            this.formulario_bitacora_cheq.push({
              id_bitacora_inspec: this.id_bitacora_inspeccion,
              id_chequeo: chequeo.id_chequeo,
              id_respuesta:
                chequeo.respuesta !== undefined ? chequeo.respuesta : null,
              valor: chequeo.texto !== undefined ? chequeo.texto : null,
            });
          });
        });

        /* Se hace la petición usando el array que se generó en el proceso anterior */
        await apiRecepcion
          .insertBitacoraCheq(this.formulario_bitacora_cheq)
          .then(() => {
            this.formulario_bitacora_cheq = []; //Se limpia el array para evitar duplicados
            this.loader_insert_formulario = false;
            this.notif_datos_guardados = true; //Se notifica al usuario que los archivos han sido guardados
          });
      } else {
        this.notif_validacion = true;
      }
    },

    /* Consigue por petición la lista de colores disponibles */
    async getColores() {
      await apiRecepcion.getColores().then((response) => {
        this.colores = response.data;
      });
    },

    /* Consigue por petición los tipos de carrocería disponibles. Depende del tipo de vehículo. */
    async getCarroceria(tipo_vehiculo) {
      await apiRecepcion.getCarroceria(tipo_vehiculo).then((response) => {
        this.carroceria = response.data;
      });
    },

    /* Consigue por petición las marcas disponibles. Depende del tipo de vehículo. */
    async getMarcas(tipo_vehiculo) {
      await apiRecepcion.getMarcas(tipo_vehiculo).then((response) => {
        this.marcas = response.data;
      });
    },

    /* Consigue por petición la lista de empresas disponibles. */
    async getEmpresas() {
      await apiRecepcion.getEmpresas().then((response) => {
        this.empresas = response.data;
      })
    },

    /* Valida si el tipo de vehículo es tanto liviano como motocicleta, y a partir de ahí mostrar chequeos
    exclusivos de ambos vehículos, también se cambia la imagen del canvas. */
    async validarTipoVehiculo(respuesta) {
      this.disable_nav_buttons = true; // Deshabilita los botones de navegación y algunos campos mientras se hace el cambio

      if (respuesta == 8) { // Si es un liviano
        this.getMarcas("1"); //Recoge las marcas de carro
        this.getCarroceria("1"); //Recoge los tipos de carrocería de carro

        await apiRecepcion
          .getChequeosCarro() //Consigue los chequeos exclusivos para carro
          .then((response) => {
            this.categorias_modificadas.forEach((categoria) => {

              // Busca la categoría en la cual existan todos estos chequeos exclusivos
              if (
                categoria.categoria ==
                "REQUISITOS PARA REALIZAR LA REVISIÓN TÉCNICO MECÁNICA Y DE EMISIONES CONTAMINANTES"
              ) {
                categoria.chequeos.splice(0); // Elimina los chequeos de esa categoría y los reestablece de nuevo
                categoria.chequeos = response.data;
                this.cambiar_canvas_moto = false; // El canvas por defecto sigue siendo el de carro
                this.getImageURL(); // Recoge de nuevo la imagen de carro
                this.disable_nav_buttons = false; // Vuelve a habilitar los botones
              }
            });
          })
          .catch((error) => {
            console.log(error); // Emite el error por consola y habilita los botones de navegación
            this.disable_nav_buttons = false;
          });

      } else if (respuesta == 9) { // Si no, entonces verifica si es una motocicleta
        this.getMarcas("2"); // Recoge las marcas de motocicleta
        this.getCarroceria("2"); // Recoge los tipos de carrocería de motocicleta

        await apiRecepcion
          .getChequeosMoto() // Consigue los chequeos exclusivos para moto
          .then((response) => {
            this.categorias_modificadas.forEach((categoria) => {

              //Itera sobre cada categoría y encuentra dicha categoría con los chequeos exclusivos
              if (
                categoria.categoria ==
                "REQUISITOS PARA REALIZAR LA REVISIÓN TÉCNICO MECÁNICA Y DE EMISIONES CONTAMINANTES"
              ) {
                categoria.chequeos.splice(0); //Se elimina y se reestablecen los chequeos
                categoria.chequeos = response.data;
                this.cambiar_canvas_moto = true; //Cambia la imagen del canvas
                this.getImageURL(); // Consigue la URL de la imagen de moto
                this.disable_nav_buttons = false; // Habilita los botones
              }
            });
          })
          .catch((error) => {
            console.log(error); // Emite el error por consola
            this.disable_nav_buttons = false;
          });
      }
    },

    // Inserta una marca en conjunto con el tipo de vehículo al que pertenece, luego devuelve las marcas
    // del tipo de vehículo actual. 
    async insertMarca() {
      this.loader_insert_marca = true;
      await apiRecepcion.insertMarca(this.formulario_marca).then(() => { //Inserta la marca
        this.activarCrearMarca = false;
        this.loader_insert_marca = false;

        //Itera sobre cada categoría, y encuentra el chequeo que diga el tipo del vehículo
        this.categorias_modificadas.forEach((categoria) => {
          if (categoria.categoria == "DATOS VEHÍCULO") {
            categoria.chequeos.forEach((chequeo) => {
              if (chequeo.chequeo == "TIPO DE VEHÍCULO") {
                if (chequeo.respuesta !== "" && chequeo.respuesta == 8) { //Si es liviano, consigue las marcas de liviano
                  this.getMarcas("1");
                } else if (chequeo.respuesta !== "" && chequeo.respuesta == 9) { //Si es moto, consigue las marcas de moto
                  this.getMarcas("2");
                }
                else if (chequeo.respuesta == "") { //Si este campo no ha sido llenado aún, trae las marcas de carro por defecto
                  this.getMarcas("1");
                }
              }
            });
          }
        });
      });
    },

    /* Inserta un tipo de carrocería en conjunción con el tipo de vehículo al cual pertenece. Luego trae de
    vuelta las carrocerías dependiendo de qué tipo de vehículo se haya seleccionado */
    async insertCarroceria() {
      this.loader_insert_carroceria = true;
      await apiRecepcion
        .insertCarroceria(this.formulario_carroceria) // Inserta la carrocería
        .then(() => {
          this.activarCrearCarroceria = false;
          this.loader_insert_carroceria = false;

          /* Itera sobre cada categoría y ubica la que tenga el chequeo de tipo de vehículo */
          this.categorias_modificadas.forEach((categoria) => {
            if (categoria.categoria == "DATOS VEHÍCULO") {
              categoria.chequeos.forEach((chequeo) => {
                if (chequeo.chequeo == "TIPO DE VEHÍCULO") {
                  if (chequeo.respuesta !== "" && chequeo.respuesta == 8) { // Si este chequeo posee una respuesta y además es carro
                    this.getCarroceria("1"); // Consigue la carroceria de carro
                  } else if (
                    chequeo.respuesta !== "" &&
                    chequeo.respuesta == 9
                  ) { // Si es de motocicleta
                    this.getCarroceria("2"); //Consigue la carroceria de motocicleta
                  }
                  else if (chequeo.respuesta == "") { //Si no tiene alguna respuesta, se obtendrá la carrocería de liviano por defecto
                    this.getCarroceria("1");
                  }
                }
              });
            }
          });
        });
    },

    /* Inserta un color a la lista y devuelve la lista actual de colores. */
    async insertColor() {
      this.loader_insert_color = true;

      await apiRecepcion.insertColor(this.formulario_color).then(() => {
        this.activarCrearColor = false;
        this.loader_insert_color = false;

        this.getColores();
      });
    },

    /* Inserta una empresa certificadora y devuelve la lista de las mismas. */
    async insertEmpresa() {
      this.loader_insert_empresa = true;

      await apiRecepcion.insertEmpresa(this.formulario_empresa).then(() => {
        this.activarCrearEmpresa = false;
        this.loader_insert_empresa = false;

        this.getEmpresas();
      })
    },

    /* Consigue la lista de servicios disponibles */
    async getServicios() {
      await apiRecepcion.getServicios().then((response) => {
        this.servicios = response.data;
      });
    },

    /* Consigue los términos contractuales disponibles que hay en configuración. */
    async getTerminosContractuales() {
      await apiRecepcion.getTerminos().then((response) => {
        this.condiciones_contractuales = response.data;
      });

      await apiRecepcion.getPrivacidad().then((response) => {
        this.privacidad = response.data;
      });
    },

    /* Limpia todos los trazos de la firma */
    limpiarFirmaPropietario() {
      this.$refs.signaturePadFirma[0].clearSignature();
    },

    // Guarda la firma del propietario de la siguiente manera:
    guardarFirmaPropietario() {
      try {

        /* Se usa sintaxis de desestructuración de objetos en donde podemos extraer las propiedades de la
        firma al guardar */
        var { data, isEmpty } = this.$refs.signaturePadFirma[0].saveSignature();

        // Si la firma no está vacía
        if (isEmpty == false) {
          mergeImages([this.fondo_blanco, data]) // Se junta la imagen con un fondo blanco
            .then((b64) => (data = b64)) // La propiedad de data toma el base64 con el fondo
            .then(() => (this.formulario_bitacora_inspec.firma = data)); // Luego se toma la data y se le asigna a la firma
        }
      } catch (error) {
        console.log(error); // Emite un error en consola
      }
    },

    getImageURL() {
      var url_host = window.location.origin; //Consigue la URL principal
      var url_imagen; // Se inicializa esta variable que almacenará una imagen o la otra

      // Si no hay algún cambio al cambiar el canvas
      if (this.cambiar_canvas_moto == false) {
        url_imagen = imagen_carro; // Almacena la imagen del carro en forma de url

        const imagen_resultado = url_host + url_imagen; //Junta la url principal y la url de la imagen

        // Si el array de imágenes ya tenía una imagen
        if (this.imagenes_revisiones.length >= 1) {
          this.imagenes_revisiones.splice(0); //Se remueve la imagen anterior
          this.imagenes_revisiones.push(imagen_resultado); // Se añade la otra
        } else {
          this.imagenes_revisiones.push(imagen_resultado); // Si no hay imágenes en este array, se añade el archivo
        }

        // Si hubo un cambio en el canvas
      } else {
        url_imagen = imagen_moto; // Almacena la imagen de la moto

        const imagen_resultado = url_host + url_imagen; // Junta la URL principal con la URL de la imagen

        /* Si el array de imágenes ya tenía una imagen */
        if (this.imagenes_revisiones.length >= 1) {
          this.imagenes_revisiones.splice(0); // Se elimina la imagen anterior
          this.imagenes_revisiones.push(imagen_resultado); // Añade la imagen actual
        } else {
          //Si no hay imágenes en el array, se añade la imagen actual de todos modos
          this.imagenes_revisiones.push(imagen_resultado);
        }
      }
    },

    /* Consigue una imagen de fondo blanco */
    async getFondoBlanco() {
      var url_imagen = fondo_blanco;

      this.fondo_blanco = url_imagen;
    },

    /* Guarda la imagen del reporte de daños, en caso de que sea una motocicleta */
    guardarImagenDanosMotos() {

      // Se instancia un objeto en el cual sus propiedades se van a asignar una vez el canvas se guarde
      var imageObj = {
        data: "",
        isEmpty: null,
      };

      this.loader_guardar_danos_motos = true;

      // Se asignan a las propiedades anteriormente creadas una vez guarde
      imageObj = this.$refs.signaturePadDanosMotos[0].saveSignature();

      // Si el canvas no está vacío
      if (imageObj.isEmpty == false) {
        mergeImages([this.imagenes_revisiones[0], imageObj.data]) //Combina la imagen del vehículo con lo dibujado en el canvas
          .then((b64) => (imageObj.data = b64)) // El base64 modificado se asigna a la data
          .then(() =>

          /* Itera por cada categoría y encuentra el chequeo que contiene el canvas */
            this.categorias_modificadas.forEach((categoria) => {
              categoria.chequeos.forEach((chequeo) => {
                if (chequeo.chequeo.includes("DAÑOS")) {
                  chequeo.texto = imageObj.data; //Asigna la respuesta la data ya procesada
                }
              });
            }),

            this.loader_guardar_danos_motos = false
          );
      }
    },

    // Limpia el canvas de reporte de daños, en caso de que sea de carro
    limpiarImagenDanos() {
      this.$refs.signaturePadDanos[0].clearSignature();
    },

    // Guarda el canvas de daños, en caso de que sea de carro
    guardarImagenDanos() {

      //Se crea un objeto cuyas propiedades se van a asignar una vez se guarde el canvas
      var imageObj = {
        data: "",
        isEmpty: null,
      };

      this.loader_guardar_danos = true;

      imageObj = this.$refs.signaturePadDanos[0].saveSignature(); //Se guarda el canvas

      //Si el canvas no está vacío
      if (imageObj.isEmpty == false) {
        mergeImages([this.imagenes_revisiones[0], imageObj.data]) //Combina la imagen del vehículo con lo dibujado
          .then((b64) => (imageObj.data = b64)) //Asigna el base64 modificado a la data
          .then(() =>

          // Recorre cada categoría y encuentra el chequeo que pertenezca al canvas, y se la asigna al texto
            this.categorias_modificadas.forEach((categoria) => {
              categoria.chequeos.forEach((chequeo) => {
                if (chequeo.chequeo.includes("DAÑOS")) {
                  chequeo.texto = imageObj.data;
                }
              });
            }),

            this.loader_guardar_danos = false
          );

        
      }
    },

    //Limpia todo trazo del canvas de motos
    limpiarImagenDanosMotos() {
      this.$refs.signaturePadDanosMotos[0].clearSignature();
    },
  },
};
</script>

<style>
#card-chequeo {
  border: 1px solid rgba(204, 204, 204, 0.8);
  border-radius: 5px;
}

#titulo-formulario {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

#checkbox-politica.v-label {
  font-size: 12px !important;
}

#canvas {
  border: 1px solid rgba(145, 145, 145, 0.8);
  border-radius: 5px;
}

#canvas-danos {
  border: 1px solid rgba(145, 145, 145, 0.8);
  border-radius: 5px;
  background-image: url("../../assets/revision_carro_resize.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#canvas-danos-motos {
  border: 1px solid rgba(145, 145, 145, 0.8);
  border-radius: 5px;
  background-image: url("../../assets/revision_moto_resize.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
